import soverom from './images/02.png';
import okonomi from './images/03.png';
import godtnorsk from './images/05.png';
import vandel from './images/06.png';
import frikjopesjobb from './images/07.png';
import helse from './images/spm6.png';
import tidligerearbeid from './images/spm8.png';

export default [
  {
    question: 'Har du god vandel?',
    step: 1,
    image: vandel,
    answer: 'true',
  },
  {
    question: 'Har du stabil økonomi?',
    step: 2,
    image: okonomi,
    answer: 'true',
  },
  {
    question: 'Har du ledig soverom?',
    step: 3,
    image: soverom,
    answer: 'true',
  },
  {
    question: 'Leser og snakker du godt norsk?',
    step: 4,
    image: godtnorsk,
  },
  {
    question: 'Har du alminnelig god psykisk helse?',
    step: 5,
    image: helse,
    answer: 'true',
  },
  {
    question:
      'Er du villig til å frikjøpes fra jobb for å gi et barn tilpasset omsorg på heltid eller deltid?',
    step: 6,
    image: frikjopesjobb,
    answer: 'true',
  },
  {
    question:
      'Har du jobbet med barn og ungdom over tid, utdanning innen helse- og sosialfag eller vært besøks-/beredskaps-/fosterhjem tidligere?',
    step: 7,
    image: tidligerearbeid,
  },
];
