import React from 'react';
import Button from '../styleguide/Button';
import { HorizontalSpacer, VerticalSpacer } from '../styleguide/Spacer';
import ContentSizer from '../styleguide/ContentSizer';
import Section from '../styleguide/Section';
import Card from '../styleguide/Card';
import Block from '../styleguide/Block';
import Text from '../styleguide/Text';

const Home = ({ startQuiz }) => {
  return (
    <VerticalSpacer>
      <HorizontalSpacer>
        <ContentSizer>
          <Section>
            <Card>
              <Block centered>
                <Text
                  variant="title"
                  color="primary"
                  align="center"
                  gutterBottom="large"
                >
                  Å være forsterket fosterhjem eller beredskapshjem er et
                  ansvarsfullt oppdrag
                </Text>
                <Text variant="subtitle" align="center" gutterBottom="xlarge">
                  Har du det som kreves? Ta testen vår og få en pekepinn på om
                  det er deg vi leter etter.
                </Text>
                <Button onClick={startQuiz}>Start test</Button>
              </Block>
            </Card>
          </Section>
        </ContentSizer>
      </HorizontalSpacer>
    </VerticalSpacer>
  );
};

export default Home;
