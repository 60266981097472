
import quiz from "./quiz";

export default function calculateResult(answers) {
  // Check answers again quiz object. If answer is not defined it does not matter what user answered.
  for(let i = 0; i < answers.length; i++) {
    const question = quiz[i];
    if (question.answer && question.answer !== answers[i]) {
      return false;
    }
  }

  return true;
}
